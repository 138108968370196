<template>
  <!-- card-hover-shadow  -->
  <base-card
    class="card-hover"
    :class="cardHoverShadow ? 'card-hover-shadow' : ''"
    v-if="content"
  >
    <router-link
    
      :to="{ path: topath, query: { item_id: content.item_id, type: webtype } }"
      class="text-decoration-none"
    >
      <v-img :src="content.pic_url" max-height="200"></v-img>
    </router-link>
    <v-card-text class="d-flex justify-content-between align-end">
      <div class="flex-grow-1 my-3">
        <h6 class="mb-0 grey--text text--darken-4 text-line2">{{ content.title }}</h6>
        <div class="d-flex mb-1">
          <v-rating
            :value="4.5"
            color="amber"
            dense
            half-increments
            readonly
            size="14"
          ></v-rating>

          <div class="grey--text ml-1">4.5</div>
        </div>
        <div class="d-flex">
          <h6 class="primary--text mr-2 mb-0">
            {{ content.price | rateFilter }}
          </h6>
          <h6 class="gray--text lighten-4 text-decoration-line-through mb-0">
            {{ content.oldprice | rateFilter }}
          </h6>
        </div>
      </div>

      <div class="d-flex flex-column">
        <router-link
          :to="{
            path: topath,
            query: { item_id: content.item_id, type: webtype },
          }"
          class="text-decoration-none"
        >
          <v-btn class="rounded" outlined fab x-small tile color="primary">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </router-link>
      </div>
    </v-card-text>
  </base-card>
</template>
<script>
export default {
  props: {
    cardHoverShadow: {
      type: Boolean,
      default: false,
    },
    webtype: {
      type: String,
      default: "tao",
    },
    content: Object,
  },

  data() {
    return {
      dialog: false,
      topath: "/single-product",
    };
  },
  mounted() {
    if (this.webtype == "tao") {
      this.topath = "/single-product";
    } else {
      this.topath = "/add-address";
    }
  },
  watch: {
    webtype(val) {
      if (val === "tao") {
        this.topath = "/single-product";
      } else {
        this.topath = "/add-address";
      }
      this.webtype = val;
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.card-hover {
  &:hover {
    .card-hover-icon {
      display: block;
    }
  }
  .card-hover-icon {
    display: none;
  }
}
.text-line2{
  overflow: hidden;    

  text-overflow: ellipsis;
  
  display: -webkit-box;-webkit-line-clamp: 1;  
  
   -webkit-box-orient: vertical;
  }
</style>