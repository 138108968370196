<template>
  <div class>
    <!-- section-1  -->
    <v-container>
      <v-row class="mb-6">
        <v-col cols="12" xl="12" lg="12">
          <v-carousel
            v-model="model"
            delimiter-icon="mdi-minus-thick"
            hide-delimiter-background
            show-arrows-on-hover
            cycle
            height="300"
            :show-arrows="false"
          >
            <v-carousel-item v-for="(color, i) in heroSlider" :key="i">
              <v-sheet height="100%" tile>
                <v-img :src="color.value"></v-img>
                <!-- <v-row class="fill-height" align="center" justify="center">
                  <div class="text-h2">{{}}</div>
                </v-row>-->
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
    </v-container>
    <!-- section-5 -->
    <div class="container mb-60 baojia-box liucheng-top rounded-lg">
      <v-row>
        <v-col cols="12">
          <!-- <v-card flat> -->
          <div class="text-center d-flex align-center justify-center my-4">
            <v-img class="flex-inherit" width="28" src="@/assets/images/icon/icon_red_left.svg"></v-img>
            <span class="font-600 ml-4 mr-4 text-body-1 font-weight-bold">{{ $t("如何所得返利？") }}</span>
            <router-link to="/fanli-Strategy" class="grey--text d-flex align-center">
              {{ $t("更多新人攻略")
              }}
              <v-icon>mdi-chevron-right</v-icon>
            </router-link>
            <v-img
              class="flex-inherit mr-4"
              width="28"
              src="@/assets/images/icon/icon_red_right.svg"
            ></v-img>
          </div>
          <div class="d-none d-sm-block">
            <v-row>
              <v-col cols="3" class="d-flex align-center justify-center">
                <v-img src="@/assets/images/home/step1.png" width="285" class="d-flex align-center">
                  <div class="pl-2 text-body-1 ml-20 pr-2">
                    <h6>{{ $t("第一步:") }}{{ $t("领券") }}</h6>
                    <span>{{ $t("将购买的商品链接复制至网站") }}</span>
                  </div>
                </v-img>
              </v-col>
              <v-col cols="3" class="d-flex align-center justify-center">
                <v-img src="@/assets/images/home/step2.svg" width="285" class="d-flex align-center">
                  <div class="pl-2 text-body-1 ml-20 pr-2">
                    <h6>{{ $t("第二步:") }}{{ $t("购物") }}</h6>
                    <span>{{ $t("复制链接去原网站购物") }}</span>
                  </div>
                </v-img>
              </v-col>
              <v-col cols="3" class="d-flex align-center justify-center">
                <v-img src="@/assets/images/home/step3.svg" width="285" class="d-flex align-center">
                  <div class="pl-2 text-body-1 ml-20 pr-2">
                    <h6>{{ $t("第三步:") }}{{ $t("返利") }}</h6>
                    <span>{{ $t("确认收货后获得返利") }}</span>
                  </div>
                </v-img>
              </v-col>
              <v-col cols="3" class="d-flex align-center justify-center">
                <v-img src="@/assets/images/home/step4.svg" width="285" class="d-flex align-center">
                  <div class="pl-8 text-body-1 ml-20 pr-2">
                    <h6>{{ $t("第四步:") }}{{ $t("提现") }}</h6>
                    <span class>{{ $t("返利生效后提现至账户") }}</span>
                  </div>
                </v-img>
              </v-col>
            </v-row>
          </div>
          <!-- </v-card> -->
        </v-col>
      </v-row>
    </div>
    <!-- section-4 -->
    <div class="container mb-60 baojia-box liucheng-top rounded-lg">
      <v-row>
        <v-col cols="12" class>
          <div class="text-center d-flex align-center justify-center my-4">
            <v-img class="flex-inherit" width="28" src="@/assets/images/icon/icon_red_left.svg"></v-img>
            <span class="font-600 ml-4 mr-4 text-body-1 font-weight-bold">{{ $t("精选文章") }}</span>
            <v-img
              class="flex-inherit mr-4"
              width="28"
              src="@/assets/images/icon/icon_red_right.svg"
            ></v-img>
          </div>
          <div class="d-none d-sm-block">
            <ArticleCart :data="Articledata"></ArticleCart>
          </div>
        </v-col>
      </v-row>
    </div>
    <!-- section-2  -->
    <div class="container mb-60 liucheng-top">
        <h5 class="py-4 text-center"><span class="text-h5">{{$t('高返商品')}}</span><span class="primary--text text-h5 px-2">•</span><span class="primary--text">{{$t('每日上新')}}</span></h5>
      <!-- <v-row>
        <v-col cols="12" class="pb-0">
          <div class="text-center d-flex mb-1 align-center justify-center">
            <span class="mr-4 text-body-1 font-weight-bold">{{ $t("今日高返商品") }}</span>
            <v-divider></v-divider>
            <div v-for="(itemc, indexc) in cateData" @click="changeCate(indexc)" :key="indexc">
              <v-hover v-slot="{ hover }">
                <v-list-item :class="hover || activeindex==indexc ? 'cursor red--text' : 'cursor'">
                  <v-list-item-content class>
                    <v-list-item-title
                      :class="[hover|| activeindex==indexc ? 'red--text rounded-0' : '']"
                    >
                      <span class="mr-2 text-body-1">{{ itemc.value }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-hover>
            </div>
          </div>
        </v-col>
      </v-row> -->
      <v-row v-if="datalist && datalist.length > 0">
        <v-col
         
          xl="3"
          lg="3"
          md="4"
          sm="12"
          v-for="(item, index) in datalist"
          :key="index"
          @click="goBuyFn(item)"
        >
          <v-hover v-slot="{ hover }">
            <v-card class="mx-auto pa-4 cursor-pointer" :flat="hover ? false : true">
              <v-card max-height="200" class="overflow-hidden" flat>
                <img :src="item.pict_url" width="100%" />
              </v-card>
              <p class="text-truncate mb-0 pt-1">{{ item.title }}</p>
              <div class>
                <span class="grey--text">{{$t("剩余")}}：</span>
                <v-progress-linear height='8' :value="parseInt(item.coupon_remain_count)/parseInt(item.coupon_total_count)*100" class="rounded-xl" :rounded-bar='true'></v-progress-linear>
              </div>
              <div class="d-flex justify-space-around">
                <div class="py-2 text-center">
                  <div class="">{{$t('原价')}}</div>
                  <div class="font-weight-bold">¥{{item.zk_final_price}}</div>
                </div>
                <div class="py-2 text-center">
                  <div class="">{{$t('券')}}</div>
                  <div class="font-weight-bold">¥{{item.coupon_amount}}</div>
                </div>
                
                <div class="py-2 text-center">
                  <div class=" primary--text">{{$t('返利')}}</div>
                  <div class="font-weight-bold primary--text">¥{{item.commission}}</div>
                </div>
              </div>
            </v-card>
          </v-hover>
        </v-col>
        <v-col cols="12" class="text-center">
          <img src="@/assets/images/other/loading.gif" width="58" class="mb-6" v-show="loading" />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="3" v-for="n in 12" :key="n">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Navbar from "@/views/home/navbar";
import ArticleCart from "@/components/base/articleCard";
import CardCart from "@/components/cartCard/CardCart";
export default {
  // name: 'Home',
  components: {
    Navbar,
    CardCart,
    ArticleCart
  },
  data() {
    return {
      heroSlider: [], //banner
      activeindex: 0,
      cateData: [], //分类
      model: 0,
      params: {
        page: 1
      },
      Articledata: [],
      loading: false,
      colors: ["primary", "secondary", "yellow darken-2", "red", "orange"],
      datalist: [],
      keyword: "",
      contactdata:[]
    };
  },
  mounted() {
    this.getbanner();
    this.getnewArticle();
    window.addEventListener("scroll", this.handleScroll, true);
    
  },
  methods: {
    changeCate(index) {
      this.activeindex = index;
      this.datalist = [];
      this.params.page = 1;
      this.get_goods();
    },
    //获取商品列表
    get_goods() {
      this.loading = true;
      let param = {
        page: this.params.page
      };
      this.$api.home.get_goods(param).then(res => {
        if (res.status == "success") {
          this.loading = false;
          this.datalist = this.datalist.concat(res.data.lists);
          // location.reload();
        } else {
          this.datalist = [];
        }
      });
    },
    handleScroll() {
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop; //变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight; //变量scrollHeight是滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      if (
        scrollTop + windowHeight > scrollHeight - 120 &&
        this.loading == false &&
        this.params.page < 6
      ) {
        this.params.page++;
        this.loading = false;
        this.get_goods(this.activeindex);
      }
      // console.log('456')
    },
    getnewArticle() {
      this.$api.home
        .latestRelease({
          keyword: "",
          page: "1",
          page_size: "8"
        })
        .then(res => {
          if (res.status == "success") {
            this.Articledata = res.data;
          } else {
            this.Articledata = [];
          }
        });
    },
    // 跳转原网站
    goBuyFn(val) {
      window.open(val.click_url, "_blank");
    },
    getbanner() {
      
      this.$api.home.banner().then(res => {
        if (res.status) {
          let bannerList = [];
          
          for (let item of res.data.banner) {
            if (item.platform == "pc") {
              bannerList.push(item);
            }
          }
          this.heroSlider = bannerList;
          this.cateData = res.data.cate;
          this.get_goods();
        }
      });
    }
  }
};
</script>
<style>
.opa5 .v-image__image {
  filter: opacity(0.4);
}
</style>
<style lang="scss" scoped>
.border-jianbian {
  background: linear-gradient(#fff, #fff, #e94560);
  padding: 2px;
  position: relative;
}

.flex-inherit {
  flex: inherit;
}
.yuan0-color {
  color: #5268f2;
}
.yuan1-color {
  color: #0092e4;
}
.yuan2-color {
  color: #03cdd8;
}
.yuan3-color {
  color: #05c7a0;
}
.ml-20 {
  margin-left: 100px;
}
a {
  text-decoration: none;
}
.line2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.searchMain-input {
  border: solid 2px #fff;
}
#autocomplete-box {
  border: 0 none;
  border-bottom: 1px solid transparent;
  padding: 0 54px 0 10px;
  width: 100%;
  height: 100%;
  font-size: 15px;
  box-sizing: border-box;
  outline: 0;
}
.letter-space {
  letter-spacing: 10px !important;
}
.liucheng-top {
  margin-top: -50px;
  position: relative;
  z-index: 1;
}
@media (max-width: 2150px) {
  .liucheng-top {
    margin-top: -30px;
  }
}
@media (max-width: 2000px) {
  .liucheng-top {
     margin-top: -30px;
    // margin-top: -70px;
  }
}

@media (max-width: 1004px) {
  .liucheng-top {
     margin-top: -30px;
    // margin-top: 10px;
  }
}

@media (max-width: 1264px) {
  .liucheng-top {
    margin-top: 30px;
  }
}
@media (max-width: 960px) {
  .liucheng-top {
    margin-top: 20px;
    background-color: transparent !important;
  }
}
@media (max-width: 600px) {
  .liucheng-top {
    margin-top: 20px;
    padding: 12px !important;
  }
  .head-content {
    padding: 0px !important;
    margin: 0px !important;
  }
  .head-title {
    margin-top: 10px !important;
    margin-bottom: -10px !important;
  }
  .border-jianbian-panel {
    background-color: #fff !important;
  }
  .searchMain-input {
    width: 80% !important;
  }
  
}
.fan-box{
        border: 1px dashed #ff6600;
    color: #ff6600;
    font-size: 12px;
    padding: 0 4px;
  }
.quan-box{
        border: 1px dashed #4caf50;
    color: #4caf50;
    font-size: 12px;
    padding: 0 4px;
  }
</style>
