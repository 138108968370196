<template>
  <div class="ecom-dropdownmenu pt-0 d-none d-lg-block">
    <ul class="py-3 mb-0 w235">
      <li v-for="(nav, index) in navigations" :key="index">
        <a>
          <p class="m-0 mb-0 flex-grow-1 text-no-wrap text-truncate" :title="nav.name">{{ nav.name }}</p>
          <v-icon v-if="nav.son">mdi-chevron-right</v-icon>
        </a>
        <div v-if="nav.son.length > 0" class="sub-menu">
          <div class="mega-menu">
            <div class="row flex-wrap mb-4">
              <template v-for="(navTwo, idx) in nav.son">
                <div class="col-md-3" v-if="idx < 8" :key="idx">
                  <h6 class="fw-semibold mb-3" @click="goodlist(navTwo)">
                    {{ navTwo.name }}
                  </h6>
                  <ul>
                    <template v-for="(navThree, idxthree) in navTwo.son">
                      <li v-if="idxthree < 6" :key="idxthree" @click="goodlist(navThree)">
                        <a>{{ navThree.name }}</a>
                      </li>
                    </template>
                  </ul>
                </div>
              </template>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      navigations: [],
    };
  },
  mounted() {
    this.$api.product.cate().then((res) => {
      if (res.status) {
        this.navigations = res.data;
      }
    });
  },
  methods: {
         // 跳转列表页
    goodlist(item) {
      let name = "";
      if (!item["title"]) {
        name = item.name;
      } else {
        name = item.title;
      }
      this.$router.push({
          path: "/search-product",
          query: { type: "tao", keyword: name }
    });
    }
  },
};
</script>
<style lang="scss" scoped>
$primary-500: #d23f57;
$primary-100: #fce9ec;
$box-shadow: 0px 4px 16px rgb(43 52 69 / 10%);
$secondary: #2b3445;
$color_f5:#F5F5F5;
$gray-900: #2b3445;

// ecomdropdown
.ecom-dropdownmenu {
  padding-top: 10px;

  &.ecom-dropdownmenu-fixed {
    display: none;
    &.open {
      display: block;
    }
  }
  ul {
    background-color: rgb(255, 255, 255);

    padding: 0;
    width: 250px;
    max-width: 250px;
    box-shadow: $box-shadow;
    border: none;
    border-radius: 0.5rem;
    &.disable {
      li {
        // background-color: $gray-100;
        a {
          font-weight: bold;
        }
      }
    }
    li {
      position: relative;
      list-style-type: none;
      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.594rem 1.25rem;
        text-decoration: none;
        color: $gray-900;
      }
      &:hover > {
        a {
          background-color: $primary-100;
          color: $primary-500;
          i {
            color: $primary-500;
          }
        }
        .sub-menu {
          display: block;
        }
      }
      // child ul
      .sub-menu {
        position: absolute;
        left: 100%;
        top: 0;
        width: 100%;
        padding-left: 1rem;
        display: none;
        z-index: 10;
        ul {
          background-color: white;
          padding: 0;
          border-radius: 0.5rem;
          box-shadow: $box-shadow;
          width: 100%;
          li {
            list-style-type: none;
          }
        }

        .mega-menu {
          min-width: 600px;
          box-shadow: $box-shadow;
          padding: 1.5625rem 1.25rem;
          border-radius: 0.5rem;
          background-color: white;
          ul {
            border: none;
            box-shadow: none;
            li {
              margin-bottom: 0.625rem;
              a {
                padding: 0;
                margin: 0;
              }
              &:hover {
                a {
                  background-color: white;
                  color: $primary-500;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>